
.fileIcon {
    font-size: 100px;
    color: #2d6da3;
}
.attachmentContainer {
    display: flex;
    flex-wrap: wrap;
}

.attachmentColumn {
    width: 200px;
    text-align: center;
}

.attachmentDesc{
    margin:10px;
}

.uploadContainer{
    border:1px solid #d3d3d3;
    margin-top:20px;
    margin-bottom:10px;
}

/*.dx-fileuploader-upload-button {
    display: none;
}*/ 

