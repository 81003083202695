@import "../../themes/generated/variables.base.scss";

.footer {
    display: block;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    //color: rgba(rgba(255, 255, 255, 0.87), alpha($base-text-color) * 0.7);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 24px;
}
