.side-nav-inner-toolbar {
    width: 100%;
    //background-image: url("../../img/atc_office2.jpg");
    background-size: cover;
    background-repeat: repeat;
    height:100%;
}

.container1 {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

#navigation-header {
    @import "../../themes/generated/variables.additional.scss";
    /*background-color: $base-accent;*/
    background-color: #F2F2F2; /*#fff;*/
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding-bottom: 130px!important;

    .toolbar-head {
        width: 248px;
    }

    .sbcounty-logo {
        background: transparent url('../../img/sb_cologo-full_color.png') no-repeat;
        background-size: cover;
        width: 180px;
        height: 100px;
        display: inline-block;
        margin-left: 30px;
        margin-top: 20px;
        transition: height, 0.3s linear;
    }

    .menu-button {
        float: left;
    }

    .menu-button .dx-icon {
        /*color: $base-text-color;*/
        color: silver !important;
    }

    .screen-x-small & {
        padding-left: 20px;
    }

    .dx-theme-generic & {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .header-title {
        font-size: 1.2rem;
        font-weight: bolder;
        color: #002D73;
        margin-top: -10px;
        padding-left: 12px;
        display: inline-block;
    }
}
