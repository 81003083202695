

.uploadContainerTaxReturn {
    border: 1px solid #d3d3d3;
    margin-top: 20px;
    margin-bottom: 10px;
    
    .divFileName{
        margin:10px;
    }
}
.existingUploadedFile {
 
    margin-top: 20px;
    margin-bottom: 10px;
}
.dx-fileuploader-upload-button {
    display: none;
}
.dx-checkbox-text{
    padding-left:27px;
}


.action-button-wrapper {
    margin-top: 20px;
    padding-bottom: 8px;
    text-align: right;

    .action-button {
        margin: 0 8px 0 8px;
    }
}

.button-calculate {
    font-size: 16px;
    height: 40px;
    width: fit-content;
    padding: 7px;
    margin: 10px;
    background-color: #5cb85c;
    color: white;
}
.button-calculate:hover {
    background-color: #46a046!important;
    color: white;
}
.button-calculate:focus {
    background-color: #46a046!important;
    color: white;
}

.button-back {
    font-size: 16px;
    height: 40px;
    width: fit-content;
    padding: 7px;
    margin: 10px;
    background-color: #6c757d;
    color: white;
}
.button-back:hover {
    background-color: #5c636a!important;
    color: white;
}

.button-save {
    font-size: 16px;
    height: 40px;
    width: fit-content;
    padding: 6px;
    margin: 6px;
    background-color: #196ef9;
    color: white;
}
.button-save:hover {
    background-color: #145ed3!important;
    color: white;
}
.button-save:focus {
    background-color: #196ef9!important;
    color: white;
}


.separateLine {
    border-top: 2px solid rgba(0,45,115,.9);
}


@media print {
    * { overflow: visible !important; } 
    // .no-print { display: none !important; }
    table { page-break-inside: avoid; }
    body { zoom: 75%; }
}