.dx-invalid-message.dx-overlay-wrapper {  
    visibility: visible;  
} 

.attachmentDocType .dx-item-content.dx-list-item-content {
    height: 90px;
}


.attachmentDocType .dx-list-item-after-bag.dx-list-static-delete-button-container {
    vertical-align: top;
    padding-top: 21px;
}
