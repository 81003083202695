
.content-block {
    padding-left: 20px;
    font-size: 1.1rem;

    .taxcollector {
        font-weight: bold;
        font-size: 2em;
        color:gray;
    }

    .tot {
        font-size: 2em;
        border-bottom: 1px solid gray;
        color: gray;
    }

}

.dx-drawer-wrapper {
    background-color: #ffffff;
}

.dx-theme-generic .dx-toolbar {
    padding: 6px 20px !important;
}

.optionButtons{
    margin-right:8px;
}

.approveButton {
    background-color: #5cb85c;
    color: white;
}
.approveButton:hover {
    background-color: #46a046!important;
    color: white;
}

.denialButton {
    background-color: #d9534f;
    color: white;
}
.denialButton:hover {
    background-color: #ce312c !important;
    color: white;
}

.blueButton:hover {
    background-color: #2b6291 !important;
}

.required .dx-field-item-label-text::after {
    content: " *";
    color: #c93335; 
}

.dx-multiview-wrapper {
    padding: 25px
}