.single-card {

    .dx-card {
        max-width: 600px;
        margin: 40px auto;
        padding: 40px;
        flex-grow: 0;

        .screen-x-small & {
            width: 100%;
            height: 100%;
            border-radius: 0;
            box-shadow: none;
            margin: 0;
            border: 0;
            flex-grow: 1;
        }
    }
}
