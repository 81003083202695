.dx-datagrid-header-panel {
    display: inline-block;
}
.dx-datagrid-header-panel .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection {
    padding: 0px !important;
}

.greenButton {
    background-color: #5cb85c;
    color: white;
    border: 0px;
}
.greenButton:hover {
    background-color: #46a046!important;
    color: white;
}

#gridContainer .dx-datagrid-search-panel {
    margin-left: 0px;
}