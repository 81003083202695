

.noteContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom:10px;
}

.noteTextArea {
    width: 80%;
 
}

.noteButton{
    margin-top:62px;
    margin-left:10px;

}

