.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.option > span {
    margin-right: 10px;    
}

.dx-checkbox-text {
    margin-left: 0px !important;
}

.option > .dx-selectbox {
    display: inline-block;
    vertical-align: middle;
}