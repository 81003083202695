.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5.00);
  //display: flex;
  height: 100%;
  width: 100%;
}
.env-alert {
    background-color: #5cb85c;
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 0px 10px 10px;
}
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css");
/*@import url("../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css");*/