/*@media screen and (min-width: 768px)
{
    .jumbotron {
        margin-top: 20px;
    }
}

.jumbotron {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    color: inherit;
    background-color: #002d73;*/ /*#eeeeee;*/
    /*padding-right: 60px;
    padding-left: 60px;
}*/

.rect {
    /*text-align: center;*/
    /*font-size: 30px;*/
    padding-top: 10px;
    height: 100%;
    margin-left: 20px;
}

.main-header {
    text-align: left;
    color: #002d73;
    font-size: 30px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
 }