@import "../../themes/generated/variables.base.scss";

.login-header {
    text-align: center;
    margin-bottom: 40px;

    .title {
        color: $base-accent;
        font-weight: bold;
        font-size: 30px;
        margin: 0;
    }

    .TOTTitle {
        font-weight: normal;
        color: black;
        font-size: 30px;
        width:400px;
        height:60px
    }

    .AdminTitle {
        font-weight: normal;
        color: gray;
        font-size: 24px;
    }

    .AdminInstruction {
        font-weight: 200;
        color: gray;
        font-size: 14px;
    }
}

.loginmessage {
    color: #007dff;
    font-weight: 500;
}

.displayNone {
    display: none;
}

.dx-button.dx-state-hover {
    background-color: #009DFF !important;
}
